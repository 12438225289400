<template>
  <div>
    <Header/> 
    <div class="detail">
        <el-image   style="width:100%;height:100%;" v-if="type == 'img'" :src="logo" :preview-src-list="[logo]"></el-image>
        <VuePlayerVideo v-else :src="logo"></VuePlayerVideo>
    </div>
    <div style="width:100%;height:auto;margin-top:42vh;">
      <div style="margin-top: 15px;"></div>
      <div class="lesson-tools">
        <div style="height: 26px; margin-left: auto;" @click="collect">
          <div style="display: flex;" v-if="!collects">
            <button class="button">加書簽</button>
            <img src="../../assets/training_schedule/detail/unmark_icon.png" style="margin-right: 16px;width: 16px;height: 26px;">
          </div>
          <div style="display: flex;" v-else>
            <button class="button">已收藏</button>
            <img src="../../assets/lesson/mark_icon.png" style="margin-right: 16px;width: 16px;height: 26px;">
          </div>
        </div>
        <div style="height: 34px;">
              <button class="clear-btns round-btn" style="padding: 7px 10px;" @click="accomplish" v-if="!accomplishs">完成 >></button>
              <p style="padding: 7px 10px;color:#fff;" @click="accomplish" v-else >已完成</p>
          </div>
      </div>
      <div class="lesson-title">{{title}}</div>
      <div style="font-size: 18px; line-height: 25.2px;margin-top:3vh;" class="lesson-content" v-if="img">
        <el-image   style="width:100%;height:100%;" :src="img" :preview-src-list="[img]"></el-image>
      </div>

      <div style="font-size: 18px;line-height: 25.2px;" class="lesson-content" v-if="content.length > 0">
        <div v-for="item in content" :key="item">
          <el-image :src="item.url" :preview-src-list="[item.url]" style="padding: 10px 0;"></el-image>
          <p>{{item.contents}}</p>
        </div>
      </div>
      <div style="height:40px;"></div>
    </div>
  </div>
</template>

<style>
.lesson-content{
  padding: 15px;
  padding-top: 0;
  font-family: Helvetica Neue;
  font-weight: 300;
  font-size: 16px;
  text-align: left /* height: 100%; */;
  color:#fff;
}
.vue3-player-video .rounded-3xl{
    border-radius: 0rem;
}
.round-btn img{
  margin-right: 5px;
}
.grace-list-title {
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    justify-content: space-between;
}
.grace-list-title-desc{
  display: block;
  font-size: 10px;
  color: #999;
  line-height: 15px;
  margin-left: 9px;
  -webkit-flex-shrink: 0;
  flex-shrink: 0;
}
.grace-list-title-text {
    display: block;
    font-size: 12px!important;
    /* color: #333; */
    line-height: 19px;
    padding: 0px;
    font-weight: normal;
    width:50%;
    height:auto;
    color:#fff;
}
.grace-list-body{
  padding: 12px 0;
  margin-left: 12px;
  width: 48px;
  -webkit-box-flex: 1;
  flex: auto;
}
.grace-border-b {
    border-bottom: 1px solid #e9e9e9;
}
.grace-list-items {
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-align: center;
    -webkit-align-items: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
}
.lesson-content {
  padding: 15px;
  padding-top: 0;
  font-family: Helvetica Neue;
  font-weight: 300;
  text-align: left /* height: 100%; */;
}
.lesson-title{
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 10px;
  font-family: Helvetica Neue;
  font-weight: 400;
  font-size: 24px;
  text-align: left;
  color:#fff;
}
.round-btn{
  color: #fff;
  line-height: 130%;
  border: 1px solid #fff;
  border-radius: 999999px;
  background-color: initial;
}
.clear-btns{
  background: transparent;
}
.button{
  font-weight: 300;
  font-size: 14px;
  color: rgb(255, 255, 255);
  margin-right: 6px;
  line-height: 0;
  padding: 0;
  background: transparent;
  border: none;
}
.lesson-tools{
  height: 40px;
  margin-bottom: 10px;
  margin-right: 17px;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  align-items: center;
}
  .detail{
    height: 30vh;
    border-top: 1px solid #fff;
    position: fixed;
    top: 6vh;
    left: 0;
    width: 100%;
    z-index: 990;
  }
  /*alert 成功弹出框样式*/
.el-message--success {
  top: 80px !important;
}
 
.el-message .el-icon-success {
  font-size: 18px;
}
 
.el-message--success .el-message__content {
  font-size: 18px;
  font-weight: 700;
}
</style>
<script>
import Header from "../Header";
import VuePlayerVideo from 'vue3-player-video';
export default {
  components:{Header,VuePlayerVideo},
  data(){
    return {
      id:0,
      img:'',
      logo:'',
      title:'',
      // type 用於判斷 logo 是圖片還是視頻
      type:'img',
      content:[],
      collects:false,
      accomplishs:false
    }
  },
  created(){
    // let id = this.$route.query.id;
    // if(id > 0){
    //   this.id = id;
    //   if(id == 1){
    //     this.logo = require('@/assets/lesson/lesson_1.jpg');
    //     this.title = '第11堂：有效溝通';
    //     this.img = '';
    //     this.type='img';
    //   }else if(id == 2){
    //     this.logo = require('@/assets/lesson/lesson_1.mp4');
    //     this.title = '2021-11-02SQUID GAME 花絮';
    //     this.img = '';
    //     this.type='video';
    //   }else if(id == 3){
    //     this.logo = require('@/assets/lesson/lesson_2.mp4');
    //     this.title = 'AllStar Leadership Camp 2021';
    //     this.img = '';
    //     this.type='video';
    //     this.content = [
    //       {
    //         url:require('@/assets/lesson/lessons_1.jpg'),
    //         contents:'',
    //       },
    //       {
    //         url:require('@/assets/lesson/lessons_2.jpg'),
    //         contents:'',
    //       },
    //       {
    //         url:require('@/assets/lesson/lessons_1.jpg'),
    //         contents:'',
    //       },
    //       {
    //         url:require('@/assets/lesson/lessons_2.jpg'),
    //         contents:'',
    //       },
    //       {
    //         url:require('@/assets/lesson/lessons_1.jpg'),
    //         contents:'',
    //       },
    //       {
    //         url:require('@/assets/lesson/lessons_2.jpg'),
    //         contents:'',
    //       },
    //       {
    //         url:require('@/assets/lesson/lessons_1.jpg'),
    //         contents:'',
    //       },
    //       {
    //         url:require('@/assets/lesson/lessons_2.jpg'),
    //         contents:'',
    //       },
    //       {
    //         url:require('@/assets/lesson/lessons_1.jpg'),
    //         contents:'',
    //       },
    //       {
    //         url:require('@/assets/lesson/lessons_2.jpg'),
    //         contents:'',
    //       },
    //     ];
    //   }else if(id == 4){
    //     this.logo = require('@/assets/lesson/lesson_2.jpg');
    //     this.title = '2021年8月31日';
    //     this.img = '';
    //     this.type='img';
    //     this.content = [
    //       {
    //         url:require('@/assets/lesson/1630469386579083.jpg'),
    //         contents:'保險合約可以給你的是有承諾，⼜實在的保障。',
    //       },
    //       {
    //         url:require('@/assets/lesson/1630469386579083.jpg'),
    //         contents:'保險合約可以給你的是有承諾，⼜實在的保障。',
    //       },
    //       {
    //         url:require('@/assets/lesson/1630469386579083.jpg'),
    //         contents:'保險合約可以給你的是有承諾，⼜實在的保障。',
    //       },
    //       {
    //         url:require('@/assets/lesson/1630469386579083.jpg'),
    //         contents:'保險合約可以給你的是有承諾，⼜實在的保障。',
    //       },
    //       {
    //         url:require('@/assets/lesson/1630469386579083.jpg'),
    //         contents:'保險合約可以給你的是有承諾，⼜實在的保障。',
    //       },
    //     ];
    //   }else if(id == 5){
    //     this.logo = require('@/assets/lesson/lesson_4.jpg');
    //     this.title = '轉型的痛苦';
    //     this.img = '';
    //     this.type='img';
    //     this.content = [
    //       {
    //         url:require('@/assets/lesson/1602587821688054.jpg'),
    //         contents:'從我出道到現在，壽險業經過兩次的轉型，第一次是八十年代的PCE考試（幹掉在菜市場賣菜的叔叔），第二次是九十年代的傭金統一；這兩次的改革，都犧牲了大批不能接受（和適應）的從業員（主管比較多，代理都不懂發生什麼事）。',
    //       },
    //     ];
    //   }
    // }
  },
  methods:{
    collect(){
      if(this.collects){
        this.$message.error({message: "課程已取消收藏", duration: 10000});
      }else{
        this.$message.success({message: "課程已收藏", duration: 10000});
      }
      this.collects = !this.collects;
    },
    accomplish(){
      let id = this.$route.query.id;
      if(!id || id< 0){
        id  = 1;
      }
      this.accomplishs = true;
      this.$router.push({
        path:'/lesson_done/'+id
      });
    }
  }
}
</script>

